export enum TemplateTypes {
  INFO = 'info-request',
  APPOINTMENT = 'appointment-request',
  QUOTATION = 'quotation-request',
  USER_QUOTATION = 'user-quotation-request',
  GEO_COOKIE = 'geo-cookie',
  GEO_PRIVACY = 'geo-privacy',
  GEO_PRIVACY_CONTACTS = 'geo-privacy-contacts',
  GEO_PRIVACY_APPOINTMENT = 'geo-privacy-appointment',
  GEO_PRIVACY_NEWSLETTER = 'geo-privacy-newsletter',
  GEO_PRIVACY_BOOKING = 'geo-privacy-booking',
  GEO_PRIVACY_PAYMENTS = 'geo-privacy-payments',
  GEO_EMAIL_CLIENT = 'geo-email-client',
  GEO_EMAIL_AGENCY = 'geo-email-agency',
  HOLIDAY_QUTATION = 'holiday-quotation',
  CRUISE_QUOTATION = 'cruise-quotation',
  TOUR_QUOTATION = 'tour-quotation',
  EXPERIENCE_QUOTATION = 'experience-quotation'
}
