<div class="page--breadcrumb breadcrumb">
  <li class="breadcrumb-item">
    <span routerLink="'/'" routerLinkActive="router-link-active">
      Home
    </span>
  </li>
  <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs">
    <span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
      {{ breadcrumb.label }}
    </span>
  </li>
  <li class="breadcrumb-item" *ngIf="innerText">
    {{innerText}}
  </li>
</div>
