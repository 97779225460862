<div class="footer-main">
  <div class="footer-header">
    <div class="container container-lg">
      <div class="footer-agency">
        <img [src]="(profile$ | async)?.logo" height="40" alt="Travel">
        <div class="logo-geo">
          <span>Agenzia partner di:</span>
          <img src="./assets/images/geo_logo.png" height="40" alt="GEO Travel Network">
        </div>
      </div>
    </div>
  </div>
  <div class="footer-body">
    <div class="container container-lg">
      <div class="row">
        <!-- <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-12">
              <h4 class="h4 footer-title">Pianifica il tuo viaggio</h4>
              <ul class="footer-menu">
                <li class="footer-menu__link">
                  <span>Vacanze</span>
                </li>
                <li class="footer-menu__link">
                  <span>Crociere</span>
                </li>
                <li class="footer-menu__link">
                  <span>Voli</span>
                </li>
                <li class="footer-menu__link">
                  <span>Hotel</span>
                </li>
                <li class="footer-menu__link">
                  <span>Assistenza H24</span>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
        <div class="col-12 col-lg-6">
          <h4 class="h4 footer-title">Link Utili</h4>
          <ul class="footer-menu">
            <li class="footer-menu__link">
              <a [routerLink]="'chi-siamo'">Chi Siamo</a>
            </li>
            <li class="footer-menu__link">
              <a [routerLink]="'contatti'">Contatti</a>
            </li>
            <!--<li class="footer-menu__link">
              <a [routerLink]="'termini-e-condizioni'">Termini e Condizioni</a>
            </li>-->
            <li class="footer-menu__link">
              <a [routerLink]="'privacy-policy'">Privacy</a>
            </li>
            <li class="footer-menu__link">
              <a [routerLink]="'cookie-policy'">Informativa Cookie</a>
            </li>
            <!--<li class="footer-menu__link">
              <a href="">Accedi</a>
            </li>-->
          </ul>
        </div>
        <div class="col-12 col-lg-6 company-details">
          <h4 class="h4 footer-title">{{(profile$ | async)?.denominazione}}</h4>
          <span>Ragione sociale e sede legale:</span>
          <span>{{(profile$ | async)?.ragione_sociale}}</span>
          <span>{{(profile$ | async)?.indirizzo}}, {{(profile$ | async)?.citta}} {{(profile$ | async)?.provincia}} </span>
          <span>P.IVA: {{(profile$ | async)?.piva}}</span>
          <span>Email: <a [href]="'mailto:' + (profile$ | async)?.email_1">{{(profile$ | async)?.email_1}}</a></span>
          <span>Telefono: <a [href]="'tel:' + (profile$ | async)?.tel_1">{{(profile$ | async)?.tel_1}}</a></span>
          <span *ngIf="(profile$ | async)?.fax">Fax: <a [href]="'tel:' + (profile$ | async)?.fax">{{(profile$ | async)?.fax}}</a></span>
          <span class="extra" [innerHTML]="extraFooter$ | async"></span>
        </div>
      </div>
    </div>
  </div>
</div>
