<div class="c-info-request">
  <app-element-loader
    [loadingMessage]="loadingMessage"
    [loadingStatus]="loadingStatus"
  ></app-element-loader>
  <h5 class="c-info-request__title c-info-request__title--btn" (click)="openFormInfo = !openFormInfo">Richiedi
    informazioni</h5>
  <div class="c-info-request__content" [ngClass]="{'closed-form-info' : !openFormInfo}">
    <div class="c-info-request__contacts">
      <h6 class="h6 c-info-request__sub-title">Chiama adesso!</h6>
      <!--      <span>Contattaci dal lunedì al sabato dalle 9.30 alle 18.00</span>-->
      <div class="c-info-request__cta">
        <i class="icon-phone-shape"></i>
        <span><a [href]="'tel:' + (profile$ | async)?.tel_1">{{(profile$ | async)?.tel_1}}</a></span>
      </div>
      <!--      <div class="c-info-request__cta">-->
      <!--        <i class="icon-email"></i>-->
      <!--        <span>Chatta con noi</span>-->
      <!--      </div>-->
    </div>
    <div class="c-info-request__separator">
      <span>oppure</span>
    </div>
    <h6 class="c-info-request__sub-title">Inviaci una mail</h6>
    <form class="search-form" name="infoForm" #formReq="ngForm" (ngSubmit)="submitRequest()">
      <div class="form-group">
        <input name="userName" [(ngModel)]="name" required
               type="text" class="form-control" id="input1" placeholder="Inserisci il tuo nome">
      </div>
      <div class="form-group">
        <input name="userLastName" [(ngModel)]="surname" required
               type="text" class="form-control" id="input2" placeholder="Inserisci il tuo cognome">
      </div>
      <div class="form-group">
        <input name="userPhone" [(ngModel)]="telephone" required
               type="number" class="form-control" id="input3" placeholder="+39">
      </div>
      <div class="form-group">
        <input name="userEmail" [(ngModel)]="email" required
               type="email" class="form-control" id="input4" placeholder="Inserisci il tuo indirizzo email">
      </div>
      <div class="row">
        <div class="form-group col-6">
          <label for="input5">Numero Passeggeri</label>
          <input name="passengers" [(ngModel)]="passengers" required [max]="5" [min]="1"
                 (ngModelChange)="setAgesArray($event)"
                 type="number" class="form-control" id="input5">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label for="input6">Eta' Passeggeri</label>
          <div class="passengers-input-group">
            <input *ngFor="let age of ages; let i = index; trackBy: trackByAges"
                   [name]="'age-'+i" [(ngModel)]="ages[i]" required
                   type="number" class="form-control" id="input6-{{i}}">
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-check form-check-inline privacy-check">
          <input class="form-check-input" type="checkbox" name="consenso" id="inlineCheckbox" ngModel required>
          <label class="form-check-label" for="inlineCheckbox">
            Dichiaro di aver letto e compreso <a (click)="openPrivacy($event)">l’informativa privacy.</a>
          </label>
        </div>
      </div>
      <button type="submit" class="btn btn--blue w-100" [disabled]="formReq.invalid">Invia</button>
      <span>*campi obbligatori</span>
    </form>
    <div class="alert" [ngClass]="messageClass" *ngIf="message">{{message}}</div>
  </div>
</div>
