<div class="container-fluid p-0">
  <nav class="navbar navbar-expand-lg main-nav">
    <div class="container container-lg">
      <a class="navbar-brand main-nav__brand" [routerLink]="'/'">
        <picture>
          <source srcset="(profile$ | async)?.logo"
                  type="image/jpg" />
          <source srcset="(profile$ | async)?.logo"
                  type="image/png" />
          <img [src]="(profile$ | async)?.logo" [alt]="(profile$ | async)?.denominazione">
        </picture>
      </a>
      <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarMainNavDropdown" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
          <i class="icon-menu"></i>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarMainNavDropdown" [ngbCollapse]="!isCollapsed">
        <div class="container">
          <ul class="navbar-nav ml-lg-auto">
            <li class="nav-item main-nav-item">
              <a class="nav-link main-nav-item__link" [routerLink]="'chi-siamo'" tabindex="-1">Chi siamo</a>
            </li>
            <li class="nav-item main-nav-item">
              <a class="nav-link main-nav-item__link" [routerLink]="'tour'" tabindex="-1">Le Nostre Proposte</a>
            </li>
            <li class="nav-item main-nav-item">
              <a class="nav-link main-nav-item__link" [routerLink]="'news'" tabindex="-1">News</a>
            </li>
<!--            <li class="nav-item main-nav-item">-->
<!--              <a class="nav-link main-nav-item__link" href="" tabindex="-1">Idee di Viaggio</a>-->
<!--            </li>-->
<!--            <li class="nav-item main-nav-item">-->
<!--              <a class="nav-link main-nav-item__link" href="" tabindex="-1">Destinazioni</a>-->
<!--            </li>-->
            <li *ngFor="let link of (links$ | async)" class="nav-item main-nav-item">
              <a class="nav-link main-nav-item__link" target="{{link.target}}" href="{{link.path}}" tabindex="-1">{{link.title}}</a>
            </li>
            <li class="nav-item main-nav-item" ngbDropdown>
              <a class="nav-link main-nav-item__link" ngbDropdownToggle tabindex="-1">Servizi</a>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                <a ngbDropdownItem *ngIf="hasNewsletter" [routerLink]="'newsletter'">Newsletter</a>
                <a ngbDropdownItem *ngIf="hasServices$ | async" [routerLink]="'servizi'">Servizi Utili</a>
                <a ngbDropdownItem *ngIf="hasListe" [routerLink]="'liste-eventi'">Liste Eventi</a>
              </div>
            </li>
            <li class="nav-item main-nav-item">
              <a class="nav-link main-nav-item__link" [routerLink]="'contatti'" tabindex="-1">Contatti</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="logo-geo d-none d-lg-block">
        <span>Agenzia partner di:</span>
        <img src="./assets/images/geo_logo.png" height="40" alt="GEO Travel Network">
      </div>
    </div>
  </nav>
</div>
