import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ProjectService} from '../../services/project.service';
import {Extras} from '../../models/extras.enum';
import {IRemoteProfile} from '../../interfaces/iremote-profile';
import {PagesService} from '../../services/pages.service';
import {WebsiteConfigService} from '../../services/website-config.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {

  isCollapsed = false;

  profile$: Observable<IRemoteProfile>;
  hasServices$: Observable<boolean>;
  links$: Observable<any>;
  extrasSubscription: Subscription;
  hasNewsletter: boolean;
  hasListe: boolean;
  constructor(private _projectService: ProjectService,
              private _pagesService: PagesService,
              private _websiteConfigService: WebsiteConfigService) { }

  ngOnInit(): void {
    this.profile$ = this._projectService.getProfile();
    this.hasServices$ = this._pagesService.hasServices();
    this.links$ = this._websiteConfigService.getLinks();
    this.extrasSubscription = this._projectService.getExtras().subscribe(e => {
      e.forEach(x => {
        if (x === Extras.GEO_NEWSLETTER) {
          this.hasNewsletter = true;
        } else if (x === Extras.GEO_LISTE) {
          this.hasListe = true;
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.extrasSubscription?.unsubscribe();
  }

}
