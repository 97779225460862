<div class="top-bar-menu">
  <div class="container container-lg">
    <nav class="navbar navbar-expand-lg">
      <ul class="navbar-nav top-bar-menu__navbar top-bar-menu__navbar--contacts">
        <li class="nav-item">
          <a class="nav-link nav-item__link nav-item__link--red nav-item__link--bolded nav-item__link--phone"
             tabindex="-1"
             [href]="'mailto:' + (profile$ | async)?.email_1"
             aria-disabled="true">
            <i class="icon-email"></i>
            <span>{{(profile$ | async)?.email_1}}</span>
          </a>
        </li>
        <li class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded">
          <a [href]="'tel:' + (profile$ | async)?.tel_1">
            <i class="icon-phone"></i>
            <span>{{(profile$ | async)?.tel_1}}</span>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav top-bar-menu__navbar top-bar-menu__navbar--socials ml-auto">
        <li class="nav-item" *ngIf="(profile$ | async)?.whatsapp">
          <a [href]="'tel:' + (profile$ | async)?.whatsapp" class="navbar-brand nav-item__ico-social social-icon">
            <i class="icon-whatsapp"></i>
          </a>
        </li>
        <li class="nav-item" *ngIf="(profile$ | async)?.facebook">
          <a [href]="(profile$ | async)?.facebook" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
            <i class="icon-facebook"></i>
          </a>
        </li>
        <li class="nav-item" *ngIf="(profile$ | async)?.twitter">
          <a [href]="(profile$ | async)?.twitter" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
            <i class="icon-twitter"></i>
          </a>
        </li>
        <li class="nav-item" *ngIf="(profile$ | async)?.youtube">
          <a [href]="(profile$ | async)?.youtube" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
            <i class="icon-youtube"></i>
          </a>
        </li>
        <li class="nav-item" *ngIf="(profile$ | async)?.instagram">
          <a [href]="(profile$ | async)?.instagram" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
            <i class="icon-instagram"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
