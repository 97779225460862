<ui-modal></ui-modal>
<header *ngIf="!(isWidget$ | async)">
  <app-top-bar></app-top-bar>
  <app-nav-bar></app-nav-bar>
</header>

  <router-outlet></router-outlet>

<footer *ngIf="!(isWidget$ | async)">
  <app-footer-bar></app-footer-bar>
  <app-bottom-bar></app-bottom-bar>
</footer>
<app-page-loader></app-page-loader>

