import {Injectable} from '@angular/core';
import {ProjectService} from './project.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {AppointmentProjectTypes} from '../models/appointment-project-types.enum';

@Injectable({
  providedIn: 'root'
})
export class WebsiteConfigService {

  constructor(private _projectService: ProjectService) { }

  formatConfig(configs: any[]): any {
    const retObject = {};
    configs.forEach(e => {
      try {
        retObject[e.type] = JSON.parse(e.content);
      } catch (error) {
        retObject[e.type] = e.content;
      }
    });
    return retObject;
  }

  hasAppointments(): Observable<boolean> {
    return this._projectService.getProjectType().pipe(
      map( e => Object.values(AppointmentProjectTypes).includes(e)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAnalytics(): Observable<string> {
    return this.getAll().pipe(
      map(e => e?.analytics),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getCustomJsHtml(): Observable<string> {
    return this.getAll().pipe(
      map(e => e?.customjshtml),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getExtraFooter(): Observable<string> {
    return this.getAll().pipe(
      map(e => e?.extrafooter),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getLinks(): Observable<any> {
    return this.getAll().pipe(
      map(e => e?.links),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAll(): Observable<any> {
    return this._projectService.getWebsiteConfig().pipe(
      map(e => e ? this.formatConfig(e) : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }
}
