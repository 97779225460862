<div class="modal-generic-info">
  <div class="modal-header modal-generic-info__header">
    <h4 class="modal-title" id="modal-basic-title"><i class="icon-info"></i> {{title}}</h4>
    <button type="button" class="close modal-close" aria-label="close" (click)="activeModal.dismiss()">
      <span aria-hidden="true"><i class="icon-close"></i></span>
    </button>
  </div>
  <div class="modal-body modal-generic-info__body" [innerHTML]="body">
  </div>
</div>
