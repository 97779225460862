<div class="booking-flow__container">
  <div class="booking-flow__title booking-flow__title-steps" *ngIf="!(isB2B$ | async)">
    <h4>Info partecipanti</h4>
    <p>Inserisci tutte le informazioni relative ai partecipanti necessarie per concludere la prenotazione.</p>
  </div>
  <div class="quotation-content" *ngIf="(isB2B$ | async)">
    <h4 class="quotation-content__title quotation-content__title--border">
      <i class="icon-pin"></i> Dettagli passeggeri
    </h4>
    <div class="quotation-content__claim">
      <h4 class="quotation-content__title">Dati dei viaggiatori</h4>
      <p>Inserisci le anagrafiche dei tuoi clienti. Il primo ospite è l'intestatario della pratica.</p>
    </div>
  </div>
  <hr class="dashed-border" *ngIf="!(isB2B$ | async)">
  <div class="booking-flow__content">
    <!--<div class="information-box">
      <h6>Dubbi?</h6>
      <p>Cras quis nulla commodo, aliquam lectus sed, blandit augue. Cras ullamcorper bibendum bibendum. Duis tincidunt urna non pretium porta. Nam condimentum vitae ligula vel ornare.</p>
      <button class="btn btn&#45;&#45;blue"><i class="icon-appointment"></i> Richiedi appuntamento</button>
    </div>-->
    <form name="formGuests" #form="ngForm" (change)="formChange(form)">
      <div class="booking-flow__form">
        <div class="c-passengers-form c-passengers-form--border" *ngIf="(isB2B$ | async) && practicalReferenceAgencies?.length > 1">
          <h5 class="h5">Riferimento pratica</h5>
          <div class="form-row">
            <div class="form-group-custom col-12">
              <ng-select class="agency-ref" [ngModelOptions]="{standalone: true}" [searchFn]="customAgencySearchFn"
                [virtualScroll]="true" (ngModelChange)="setPracticalReference($event)" [items]="practicalReferenceAgencies" #refAgency="ngModel" required
                [(ngModel)]="practicalReference.agency">
                <ng-template ng-label-tmp let-item="item">
                  <span class="availability-label">
                    <strong>{{item.agencyName}} - {{item.externalAgencyId}}</strong> <br>
                    <span>{{item.address}}</span> <br>
                    <small>P.Iva: {{item.pIva}}</small> <br>
                  </span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <span class="availability-label">
                    <strong>{{item.agencyName}} - {{item.externalAgencyId}}</strong> <br>
                    <span>{{item.address}}</span> <br>
                    <small>P.Iva: {{item.pIva}}</small> <br>
                  </span>
                </ng-template>
              </ng-select>
              <small [hidden]="refAgency.valid || refAgency.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>

            </div>
          </div>
        </div>
        <div class="c-passengers-form c-passengers-form--border" *ngFor="let passenger of passengerForms">
          <span class="c-passengers-form__label" *ngIf="passenger.holder">Intestatario</span>
          <h5 class="h5">{{passenger.title}} - {{passenger.type}} <small>{{passenger.age}} anni (alla data di partenza)</small></h5>
          <div class="form-row">
            <div class="form-group-custom col-md-2">
              <label for="gender-passenger-{{passenger.id}}" class="form-group-custom__label">Sesso *</label>
              <select #gender="ngModel" class="form-control form-control-custom" name="guestGender-{{passenger.id}}"
                      id="gender-passenger-{{passenger.id}}"
                required [(ngModel)]="passenger.form.gender">
                <option value="M">M</option>
                <option value="F">F</option>
              </select>
              <small [hidden]="gender.valid || gender.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
            <div class="form-group-custom col-md-5">
              <label for="name-passenger-{{passenger.id}}" class="form-group-custom__label">Nome *</label>
              <input required [(ngModel)]="passenger.form.name"
                     #name="ngModel"
                     type="text" class="form-control form-control-custom" name="guestName-{{passenger.id}}"
                     id="name-passenger-{{passenger.id}}" placeholder="Inizia a digitare...">
              <small [hidden]="name.valid || name.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
            <div class="form-group-custom col-md-5">
              <label for="lastName-passenger-{{passenger.id}}" class="form-group-custom__label">Cognome *</label>
              <input required [(ngModel)]="passenger.form.surname"
                     #surname="ngModel"
                     type="text" class="form-control form-control-custom" name="guestLastName-{{passenger.id}}"
                     id="lastName-passenger-{{passenger.id}}" placeholder="Inizia a digitare...">
              <small [hidden]="surname.valid || surname.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group-custom col-md-7">
              <label for="birthDate-passenger-{{passenger.id}}" class="form-group-custom__label">Data di nascita *</label>
              <div
                class="input-group-custom input-group-custom--icon-append input-group-custom--icon-append--transparent"
                id="inputDepartureHoliday-dropdown">
                <input type="date" class="form-control-custom form-control-custom--date" name="guestBirthDayDate-{{passenger.id}}"
                       appGuestBirthdateValidator [guest]="passenger" [departureDate]="departureDate"
                       required [(ngModel)]="passenger.form.birthDate" (ngModelChange)="changeAge(passenger, $event)"
                       #birthDate="ngModel"
                       id="birthDate-passenger-{{passenger.id}}" placeholder="dd/mm/yyyy">
              </div>
              <!-- <small [hidden]="birthDate.valid || birthDate.untouched" class="form-group-custom__caption">Campo Obbligatorio</small> -->
              <small [hidden]="!birthDate.hasError('invalidBirthDate')" class="form-group-custom__caption">Data di nascita invalida</small>
              <small [hidden]="!passenger.changedAgeError" class="form-group-custom__caption">L'età non corrisponde a quella dichiarata</small>
            </div>
            <div class="form-group-custom col-md-5" *ngIf="passenger.holder">
              <label for="country-passenger-{{passenger.id}}" class="form-group-custom__label">Nazionalità *</label>
              <ng-select required
                         [items]="countries$ | async | orderBy"
                         [virtualScroll]="true"
                         #country="ngModel"
                         id="country-passenger-{{passenger.id}}"
                         placeholder="Seleziona la nazionalità..."
                         name="guestCountry-{{passenger.id}}"
                         class="form-control form-control-custom form-ng-select p-0"
                         (change)="onCountryChange($event, form)"
                         [(ngModel)]="passenger.form.country">
              </ng-select>
              <small [hidden]="country.valid || country.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
          </div>
          <div class="form-row" *ngIf="passenger.holder">
            <div class="form-group-custom col-md-7" *ngIf="hasFiscalCode">
              <label for="ID-passenger-{{passenger.id}}" class="form-group-custom__label">Codice fiscale *</label>
              <input required [(ngModel)]="passenger.form.fiscalCode"
                     [minLength]="16" [maxLength]="16" #fiscalCode="ngModel"
                     type="text" class="form-control form-control-custom" name="guestFiscalCode-{{passenger.id}}"
                     id="ID-passenger-{{passenger.id}}" placeholder="XXXXXXXXXX">
              <small [hidden]="fiscalCode.valid || fiscalCode.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
          </div>
          <div class="form-row" *ngIf="passenger.holder">
            <div class="form-group-custom col-md-7">
              <label for="email-passenger-{{passenger.id}}" class="form-group-custom__label">Email *</label>
              <input required [(ngModel)]="passenger.form.email" email #email="ngModel"
                     type="email" class="form-control form-control-custom" name="guestEmail-{{passenger.id}}"
                     id="email-passenger-{{passenger.id}}" placeholder="es: email@email.com">
              <small [hidden]="email.valid || email.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
            <div class="form-group-custom col-md-7">
              <label for="telephone-passenger-{{passenger.id}}" class="form-group-custom__label">Telefono *</label>
              <input required [(ngModel)]="passenger.form.telephone" #telephone="ngModel"
                     type="number" class="form-control form-control-custom" name="guestPhone-{{passenger.id}}"
                     id="telephone-passenger-{{passenger.id}}" placeholder="es: 33333333333">
              <small [hidden]="telephone.valid || telephone.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
          </div>
          <div class="form-row" *ngIf="passenger.holder">
            <div class="form-group-custom col-md-12">
              <label for="address-passenger-{{passenger.id}}" class="form-group-custom__label">Indirizzo *</label>
              <input required [(ngModel)]="passenger.form.address" #address="ngModel"
                     type="text" class="form-control form-control-custom" name="guestAddress-{{passenger.id}}"
                     id="address-passenger-{{passenger.id}}" placeholder="Inizia a digitare...">
              <small [hidden]="address.valid || address.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
            <div class="form-group-custom col-md-6">
              <label for="city-passenger-{{passenger.id}}" class="form-group-custom__label">Città *</label>
              <input required [(ngModel)]="passenger.form.city" #city="ngModel"
                     type="text" class="form-control form-control-custom" name="guestCity-{{passenger.id}}"
                     id="city-passenger-{{passenger.id}}" placeholder="Inizia a digitare...">
              <small [hidden]="city.valid || city.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
            <div class="form-group-custom col-md-3">
              <label for="postalCode-passenger-{{passenger.id}}" class="form-group-custom__label">Cap *</label>
              <input required [(ngModel)]="passenger.form.postalCode" max="99999" #postalCode="ngModel"
                     type="number" class="form-control form-control-custom" name="guestPostalCode-{{passenger.id}}"
                     id="postalCode-passenger-{{passenger.id}}" placeholder="00100">
              <small [hidden]="postalCode.valid || postalCode.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
            <div class="form-group-custom col-md-3">
              <label for="province-passenger-{{passenger.id}}" class="form-group-custom__label">Prov.</label>
              <input required [(ngModel)]="passenger.form.province" maxlength="2" #province="ngModel"
                     type="text" class="form-control form-control-custom" name="guestProvince-{{passenger.id}}"
                     id="province-passenger-{{passenger.id}}" placeholder="RM">
              <small [hidden]="province.valid || province.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
            </div>
          </div>
          <a *ngIf="passenger.holder" (click)="toggleNotes()"
                  class="page__link page__link--underline page__link--brand-color">Aggiungi note
          </a>
          <div class="form-row mt-3" *ngIf="passenger.holder && showNotes">
            <div class="form-group-custom col-md-12">
              <textarea class="form-control-custom" name="guestNote-{{passenger.id}}"
                        [(ngModel)]="passenger.form.notes"
                        id="note-passenger-{{passenger.id}}" placeholder="Inizia a digitare..." rows="3"></textarea>
            </div>
          </div>
        </div>
        <hr class="dashed-border dashed-border--small my-5">
      </div>
      <!-- <button class="btn btn--light-blue w-100 verifyAvailability" *ngIf="changedAges" (click)="refreshQuotationPrice()">
        Verifica disponibilità per le età inserite
      </button> -->
      <div class="booking-flow__additional-options">
        <div class="c-passengers-form c-passengers-form__privacy" *ngIf="!isReservation">
          <h5 class="h5">{{this.privacyContent.privacy_passenger.title}}</h5>
          <span>
            {{this.privacyContent.privacy_passenger.content}}<br />
            Leggi <a class="page__link page__link--underline" (click)="openPrivacy($event)">l’informativa privacy.</a>
          </span>
          <label class="form-check-custom"> Acconsento
            <input required type="checkbox" name="privacy" #privacy="ngModel" [(ngModel)]="privacyCheck" (ngModelChange)="onPrivacyUpdated()">
            <span class="form-check-custom__check"></span>
          </label>
          <small [hidden]="privacy.valid || privacy.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
        </div>
        <div class="c-passengers-form c-passengers-form__privacy" *ngIf="isReservation">
          <h5 class="h5">{{this.privacyContent.privacy_passenger_reservation.title}}</h5>
          <span>
            {{this.privacyContent.privacy_passenger_reservation.content}}<br />
            Leggi <a class="page__link page__link--underline" (click)="openReservationPrivacy($event)">l’informativa privacy.</a>
          </span>
          <label class="form-check-custom"> Acconsento
            <input required type="checkbox" name="privacy" #privacy="ngModel" [(ngModel)]="privacyCheck" (ngModelChange)="onPrivacyUpdated()">
            <span class="form-check-custom__check"></span>
          </label>
          <small [hidden]="privacy.valid || privacy.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
        </div>
        <div class="c-passengers-form c-passengers-form__privacy" *ngIf="topDocuments?.length > 0">
          <h5 class="h5">{{this.privacyContent.privacy_top.title}}</h5>
          <span>
            {{this.privacyContent.privacy_top.content}}<br />
            <ng-container *ngFor="let document of topDocuments">
              Leggi <a class="page__link page__link--underline" target="_blank" [href]="document.url">{{document.title}}</a> <br />
            </ng-container>
          </span>
          <label class="form-check-custom"> Acconsento
            <input required type="checkbox" name="privacyTop" #privacyTop="ngModel" [(ngModel)]="privacyTopCheck" (ngModelChange)="onPrivacyUpdated()">
            <span class="form-check-custom__check"></span>
          </label>
          <small [hidden]="privacyTop.valid || privacyTop.untouched" class="form-group-custom__caption">Campo Obbligatorio</small>
        </div>
        <div class="c-passengers-form c-passengers-form__newsletter" *ngIf="hasGeoNewsletter$ | async">
          <h5 class="h5">Consenso per l’invio di comunicazioni promozionali e di marketing</h5>
          <span>
            I dati verranno utilizzati per l'invio di comunicazioni promozionali e di marketing, incluso l'invio di newsletter, attraverso strumenti automatizzati (e-mail).<br />
            Leggi <a class="page__link page__link--underline" (click)="openNewsletterPrivacy($event)">l’informativa privacy.</a></span>
          <label class="form-check-custom"> Acconsento al trattamento dei suoi dati personali per comunicazioni promozionali e di marketing
            <input type="checkbox" name="newsletter" #newsletter="ngModel" [(ngModel)]="newsletterCheck">
            <span class="form-check-custom__check"></span>
          </label>
        </div>
      </div>
    </form>
  </div>
</div>
