import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LoadingItem} from '../interfaces/loading-item';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loadingMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private loadingStack: LoadingItem[] = [];

  constructor() {
  }

  private updateLoader(): void {
    this.loadingStatus.next(this.loadingStack.length > 0);
    if (this.loadingStack.length > 0) {
      this.loadingMessage.next(this.loadingStack[this.loadingStack.length - 1].message);
    } else {
      this.loadingMessage.next('');
    }
  }

  addLoad(loadingItem: LoadingItem): void {
    this.loadingStack.push(loadingItem);
    this.updateLoader();
  }

  removeLoad(loadingItem: LoadingItem): void {
    this.loadingStack = this.loadingStack.filter(e => e.id !== loadingItem.id);
    this.updateLoader();
  }

  getLoadingStatus(): Observable<boolean> {
    return this.loadingStatus;
  }

  getLoadingMessage(): Observable<string> {
    return this.loadingMessage;
  }
}
