import { Component, OnDestroy, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import 'dayjs/locale/it';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { Observable, Subscription } from 'rxjs';
import { CmsService } from './modules/cms/services/cms.service';
import { MetaService } from './modules/shared/services/meta.service';
import { PostMessageService } from './modules/shared/services/post-message.service';
import { WebsiteConfigService } from './modules/shared/services/website-config.service';
import { WidgetService } from './modules/shared/services/widget.service';
import stringToElement from './utils/string-to-element';
import {Networks} from './modules/shared/models/networks.enum';
import {ProjectService} from './modules/shared/services/project.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';

  constructor(private _websiteConfigService: WebsiteConfigService,
              private _widgetService: WidgetService,
              private _postMessageService: PostMessageService,
              private _metaService: MetaService,
              private _cmsService: CmsService,
              private _projectService: ProjectService
  ) { }

  isWidget$: Observable<boolean>;
  isB2B$: Observable<boolean>;
  isWTG: boolean;
  customJsHtmlSubscription: Subscription;
  isWidgetSubscription: Subscription;
  isB2BSubscription: Subscription;

  ngOnInit(): void {
    dayjs.locale('it');
    dayjs.extend(customParseFormat);
    this.isWidget$ = this._widgetService.isWidget();
    this.isWidgetSubscription = this.isWidget$.subscribe(e => {
      if (e) {
        document.body.classList.add('is-widget');
        setTimeout(() => {
          ocm?.autoAccept();
        }, 1000);
      } else {
        this._cmsService.initialise();
        this._metaService.addMeta('google-site-verification', 'UUj6BrHk5c8V_OImdf8Z-C2qkn1WgG4gDWYD_E8n9Mw');
        setTimeout(() => {
          ocm?.init();
        }, 1000);
      }
    });

    this._projectService.getAgencyNetwork().toPromise().then(
      (resolve) => {
        this.isWTG = Number(resolve.id_network) && Number(resolve.id_network) === Networks.WTG;
        if (this.isWTG) {
          document.body.classList.add('is-wtg');
        }
      },
      (reject) => {
        console.error(reject);
      }
    );

    this.isB2BSubscription = this._postMessageService.isB2B().subscribe(x => {
      if (x) {
        document.body.classList.add('is-b2b');
      } else {
        this.customJsHtmlSubscription = this._websiteConfigService.getCustomJsHtml().subscribe(e => {
          if (e) {
            document.body.append(stringToElement(`<div id="customjshtml">${e}</div>`));
            document.querySelectorAll('#customjshtml > script').forEach(element => {
              eval(element.innerHTML);
              console.log(element.innerHTML);
            });
          }
        });
      }
    });

    this._projectService.getProfile().toPromise().then(
      (resolved) => {
        if (resolved?.email_1?.trim()?.length === 0 && resolved?.email_2?.trim()?.length === 0) {
          alert('Configurazione errata.');
          window.stop();
          throw new Error('Configurazione Errata del sito.');
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.customJsHtmlSubscription?.unsubscribe();
    this.isWidgetSubscription?.unsubscribe();
    this.isB2BSubscription?.unsubscribe();
  }

}
