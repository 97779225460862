import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateSelectorComponent } from './components/template-selector/template-selector.component';
import { ImageSelectorComponent } from './components/image-selector/image-selector.component';
import { FinalizeLayoutComponent } from './components/finalize-layout/finalize-layout.component';
import { PosterBuilderComponent } from './components/poster-builder/poster-builder.component';
import { TemplatePreviewComponent } from './components/template-preview/template-preview.component';
import { WtgA4VerticalNoPhotoComponent } from './posters/wtg-a4-vertical-no-photo/wtg-a4-vertical-no-photo.component';
import { WtgA4VerticalPhotoComponent } from './posters/wtg-a4-vertical-photo/wtg-a4-vertical-photo.component';
import { WtgSocialFbComponent } from './posters/wtg-social-fb/wtg-social-fb.component';
import { GeoA4VerticalNoPhotoComponent } from './posters/geo-a4-vertical-no-photo/geo-a4-vertical-no-photo.component';
import { GeoA4VerticalPhotoComponent } from './posters/geo-a4-vertical-photo/geo-a4-vertical-photo.component';
import { GeoSocialFbComponent } from './posters/geo-social-fb/geo-social-fb.component';
import { SharedModule } from '../shared/shared.module';
import { ImageByCodePipe } from '../shared/pipes/image-by-code.pipe';

@NgModule({
  declarations: [
    TemplateSelectorComponent,
    ImageSelectorComponent,
    FinalizeLayoutComponent,
    PosterBuilderComponent,
    TemplatePreviewComponent,
    WtgA4VerticalNoPhotoComponent,
    WtgA4VerticalPhotoComponent,
    WtgSocialFbComponent,
    GeoA4VerticalNoPhotoComponent,
    GeoA4VerticalPhotoComponent,
    GeoSocialFbComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    PosterBuilderComponent
  ],
  providers: [
    ImageByCodePipe,
    DOMParser
  ]
})
export class LayoutsModule {}
