<div class="booking-tabs">
  <div class="container container-lg p-0">
    <div class="booking-tabs__nav">
      <swiper [config]="config" [(index)]="index">
        <div [ngClass]="{'active': currentStep === step, 'active-done': currentStep.id > step.id, 'disabled': step.disabled}"
             (click)="selectStep(step)"
             *ngFor="let step of steps | filterBy: ['hidden'] : [false]"
          class="booking-tabs-item">
          <div class="booking-tabs-item__icon">
            <i [ngClass]="step.icon"></i>
          </div>
          <div class="booking-tabs-item__desc">
            <h4>{{step.name}}</h4>
            <span>Step {{(steps | filterBy: ['hidden'] : [false]).indexOf(step) + 1}} di {{totalSteps}}</span>
          </div>
        </div>
      </swiper>
    </div>
  </div>
</div>
