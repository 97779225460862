import {Component, OnInit} from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {Observable} from 'rxjs';
import {IRemoteProfile} from '../../interfaces/iremote-profile';
import {WebsiteConfigService} from '../../services/website-config.service';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit {

  constructor(private _projectService: ProjectService,
              private _websiteConfigService: WebsiteConfigService) { }

  profile$: Observable<IRemoteProfile>;
  extraFooter$: Observable<string>;

  ngOnInit(): void {
    this.profile$ = this._projectService.getProfile();
    this.extraFooter$ = this._websiteConfigService.getExtraFooter();
  }

}
