<div class="footer-copyright">
  <div class="container container-lg">
    <div class="footer-copyright__line">
      <p class="footer-copyright__text">
        <span>Copyright © 2021 {{(profile$ | async)?.denominazione}} - P.I. {{(profile$ | async)?.piva}}</span>
        <span>Proudly powered by <a target="_blank" href="https://www.otosrl.com/">OTO</a></span>
      </p>
    </div>
  </div>
</div>
