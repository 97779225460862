import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { forkJoin, Observable, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, shareReplay } from 'rxjs/operators';
import { IHolidayQuotationAgency } from 'src/app/modules/booking-flow/holiday/holiday-booking-page/interfaces/iholiday-quotation-params';
import * as HolidayBookingFormPrivacyContent from '../../../../../assets/data/holidaybookingformprivacycontent.json';
import { IPassengerForm } from '../../interfaces/ipassenger-form';
import { IQuotationReference } from '../../interfaces/iquotation-reference';
import { IRemoteProfile } from '../../interfaces/iremote-profile';
import { LoadingItem } from '../../interfaces/loading-item';
import { CountriesStrings } from '../../models/countries-strings.enum';
import { Networks } from '../../models/networks.enum';
import { TemplateTypes } from '../../models/template-types.enum';
import { CompilerService } from '../../services/compiler.service';
import { CountriesService } from '../../services/countries.service';
import { LoadingService } from '../../services/loading.service';
import { PostMessageService } from '../../services/post-message.service';
import { ProjectService } from '../../services/project.service';
import { TemplatesService } from '../../services/templates.service';
import { ModalGenericInfoComponent } from '../modal-generic-info/modal-generic-info.component';
import { Occupancy } from './../../interfaces/occupancy';
import { BookingService } from './../../services/booking.service';

@Component({
  selector: 'app-booking-guests-info',
  templateUrl: './booking-guests-info.component.html',
  styleUrls: ['./booking-guests-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingGuestsInfoComponent implements OnInit, OnDestroy {

  @ViewChild(NgForm) form;
  // @Input() occupancy: Occupancy;
  @Input() isReservation: boolean;
  @Input() topDocuments: any[];
  @Input() departureDate: string;
  @Output() formUpdateEvent = new EventEmitter<{form: NgForm, object: IPassengerForm[], changedAges: boolean}>();
  @Output() privacyUpdateEvent = new EventEmitter<{privacyContent: {title: string; content: string}; hasPrivacyAccepted: boolean}>();
  @Output() agencySelected = new EventEmitter<IHolidayQuotationAgency>();

  occupancy: Occupancy;
  occupancySub: Subscription;

  changedAges = false;
  practicalReference: IQuotationReference;
  passengerForms: IPassengerForm[] = [];
  hasFiscalCode = false;
  showNotes = false;
  privacyCheck = false;
  privacyTopCheck = false;
  newsletterCheck = false;
  profile$: Observable<IRemoteProfile>;
  isB2B$: Observable<boolean>;
  hasGeoNewsletter$: Observable<boolean>;
  countries$: Observable<string[]>;
  practicalReferenceAgencies: IHolidayQuotationAgency[];

  loggedUser: any;
  // @ts-ignore
  privacyContent: typeof HolidayBookingFormPrivacyContent = HolidayBookingFormPrivacyContent.default;

  constructor(private _modalService: NgbModal,
              private _templatesService: TemplatesService,
              private _compilerService: CompilerService,
              private _loadingService: LoadingService,
              private _postMessageService: PostMessageService,
              private _countriesService: CountriesService,
              private _bookingService: BookingService,
              private _changeDetectorRef: ChangeDetectorRef,
              private _projectService: ProjectService) { }

  ngOnInit(): void {
    this.practicalReference = {agencyName: ''};
    this.profile$ = this._projectService.getProfile();
    this.isB2B$ = this._postMessageService.isB2B();
    this.occupancySub = this._bookingService.occupancy$.subscribe(occupancy => {
      if (occupancy) {
          this.occupancy = occupancy;
          if (this.passengerForms.length > 0) {
            this.occupancy.ages.forEach((age: number, ageIndex: number) => {
              if (this.passengerForms[ageIndex].age !== age) {
                this.passengerForms[ageIndex].age = age;
                this.passengerForms[ageIndex].changedAgeError = false;
              }
            });
            this.changedAges = false;
            this._changeDetectorRef.detectChanges();
          } else {
            this.createForms();
          }
      }
    });
    this.hasGeoNewsletter$ = forkJoin([this._projectService.hasNewsletter(), this._projectService.getAgencyNetwork()])
      .pipe(
        map((e) => {
          const [hasNewsletter, agencyNetwork] = e;
          return (hasNewsletter && parseInt(agencyNetwork.id_network, 10) === Networks.GEO);
        }),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
      );
    this.countries$ = this._countriesService.getFormattedCountries();
    this._postMessageService.getQueryParam('token').then(
      (token) => {
        if (token && token.length > 0) {
          this._bookingService.getChildAgencies(token).then(
            (agencies) => {
              this.practicalReferenceAgencies = agencies;
              if (this.practicalReferenceAgencies?.length === 1) {
                this.practicalReference.agency = this.practicalReferenceAgencies[0];
                this.agencySelected.emit(this.practicalReference.agency);
              }
            },
            (rejected) => {
              console.error(rejected);
            }
          );
        } else {
          this._projectService
            .getProfile()
            .toPromise()
            .then(
              (user: IRemoteProfile) => {
                this.loggedUser = user;
                this.practicalReference.agency = {
                  address: user.indirizzo,
                  agencyId: Number(user.id),
                  agencyName: user.denominazione,
                  pIva: user.piva,
                  externalAgencyId: null,
                };
                this.agencySelected.emit(this.practicalReference.agency);
              },
              (rejected: any) => {
                console.error(rejected);
              }
            );
        }
      },
      (rejected) => {
        console.error(rejected);
      }
    );
  }

  ngOnDestroy(): void {
    this.occupancySub.unsubscribe();
  }

  setPracticalReference(agency: IHolidayQuotationAgency): void {
    this.agencySelected.emit(agency);
  }

  customAgencySearchFn(term: string, item: IHolidayQuotationAgency): boolean {
    return item.address.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
    item.agencyName.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
    item.externalAgencyId.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
    item.pIva.toLowerCase().indexOf(term.toLowerCase()) !== -1;
  }

  onCountryChange($event: string, form: NgForm): void {
    this.hasFiscalCode = $event === CountriesStrings.ITALY;
    setTimeout(() => this.formChange(form));
  }

  formChange(form: NgForm): void {
    this.formUpdateEvent.emit({form, object: this.passengerForms, changedAges: this.changedAges});
  }

  toggleNotes(): void {
    this.showNotes = !this.showNotes;
  }

  createForms(): void {
    this.passengerForms = [];
    let ospiteNum = 1;
    this.occupancy.ages.forEach(e => {
      const tmpForm: IPassengerForm = {
        id: ospiteNum,
        title: `Ospite ${ospiteNum}`,
        holder: ospiteNum === 1,
        age: e,
        // @ts-ignore
        form: {}
      };
      this.passengerForms.push(tmpForm);
      ospiteNum++;
    });
  }

  onPrivacyUpdated(): void {
    this.privacyUpdateEvent.emit({
      privacyContent: (this.isReservation) ? this.privacyContent.privacy_passenger_reservation : this.privacyContent.privacy_passenger,
      hasPrivacyAccepted: this.privacyCheck
    });
  }

  openReservationPrivacy($event: any): void {
    $event?.preventDefault();
    const loadingPrivacy: LoadingItem = {id: 'privacy', message: 'Cariamento informativa privacy...'};
    this._loadingService.addLoad(loadingPrivacy);
    forkJoin([
      this._templatesService.getTemplate(TemplateTypes.GEO_PRIVACY_PAYMENTS),
      this.profile$
    ]).pipe(
      map(e => this._compilerService.compile(e[0], e[1])),
      finalize(() => this._loadingService.removeLoad(loadingPrivacy)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    ).subscribe(p => {
      const modalRef = this._modalService.open(ModalGenericInfoComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg'
      });
      modalRef.componentInstance.title = 'Informativa privacy acquisto';
      modalRef.componentInstance.body = p;
    });
  }

  openNewsletterPrivacy($event: any): void {
    $event?.preventDefault();
    const loadingPrivacy: LoadingItem = {id: 'privacy', message: 'Cariamento informativa privacy...'};
    this._loadingService.addLoad(loadingPrivacy);
    forkJoin([
      this._templatesService.getTemplate(TemplateTypes.GEO_PRIVACY_NEWSLETTER),
      this.profile$
    ]).pipe(
      map(e => this._compilerService.compile(e[0], e[1])),
      finalize(() => this._loadingService.removeLoad(loadingPrivacy)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    ).subscribe(p => {
      const modalRef = this._modalService.open(ModalGenericInfoComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg'
      });
      modalRef.componentInstance.title = 'Informativa privacy newsletter';
      modalRef.componentInstance.body = p;
    });
  }

  openPrivacy($event: any): void {
    $event?.preventDefault();
    const loadingPrivacy: LoadingItem = {id: 'privacy', message: 'Cariamento informativa privacy...'};
    this._loadingService.addLoad(loadingPrivacy);
    forkJoin([
      this._templatesService.getTemplate(TemplateTypes.GEO_PRIVACY_BOOKING),
      this.profile$
    ]).pipe(
      map(e => this._compilerService.compile(e[0], e[1])),
      finalize(() => this._loadingService.removeLoad(loadingPrivacy)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    ).subscribe(p => {
      const modalRef = this._modalService.open(ModalGenericInfoComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg'
      });
      modalRef.componentInstance.title = 'Informativa privacy richiedi preventivo';
      modalRef.componentInstance.body = p;
    });
  }

  changeAge(passenger: IPassengerForm, newBirthDate): void {
    const today = dayjs(this.departureDate, 'YYYY-MM-DD').toDate();
    const birthDate = dayjs(newBirthDate, 'YYYY-MM-DD').toDate();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    passenger.changedAge = age;
    passenger.changedAgeError = passenger.age !== passenger.changedAge;

    this.changedAges = !!this.passengerForms?.find((passengerForm: IPassengerForm) => passengerForm.age !== passengerForm.changedAge);
  }
}
