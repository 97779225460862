import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BookingStep} from '../../interfaces/booking-step';
import {BookingStepTypes} from '../../models/booking-step-types.enum';

@Component({
  selector: 'app-booking-steps',
  templateUrl: './booking-steps.component.html',
  styleUrls: ['./booking-steps.component.scss']
})
export class BookingStepsComponent implements OnInit {

  @Input() steps: BookingStep[];
  @Input() currentStep: BookingStep;
  @Input() totalSteps: number;
  @Output() stepSelect = new EventEmitter<BookingStepTypes>();

  config = {
    breakpoints: {
      320: {
        centeredSlides: true,
        allowTouchMove: true
      },
      992: {
        centeredSlides: false,
        allowTouchMove: false
      }
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  };
  index;

  constructor() {
  }

  selectStep(step: BookingStep): void {
    if (!step.disabled && step.id < this.currentStep.id) {
      this.stepSelect.emit(step.type);
    }
  }

  ngOnInit(): void {
  }

}
