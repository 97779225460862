import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { IHolidayQuotationAgency } from 'src/app/modules/booking-flow/holiday/holiday-booking-page/interfaces/iholiday-quotation-params';
import { environment } from 'src/environments/environment';
import { IHolidayEditableReceiptField } from '../../b2b/components/holiday-booking-b2b/interfaces/iholiday-editable-receipt-field';
import { Occupancy } from '../interfaces/occupancy';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  occupancy: Occupancy = {
    guests: 2,
    ages: [35, 35]
  };
  occupancy$: BehaviorSubject<Occupancy> = new BehaviorSubject(this.occupancy);

  maxGuests = 5;
  maxAge = 99;

  editableReceiptFields: IHolidayEditableReceiptField[] = [];
  httpCache: Map<string, Observable<any>> = new Map<string, Observable<any>>();

  constructor(
    private _router: Router,
    private _http: HttpClient
  ) {
    this._router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationStart || e instanceof NavigationEnd) {
        // FIXME: Prevedere un modo piu' sicuro di prendere il parametro ages/eta dalla route corrente
        //  ActivatedRoute non e' accessibile a livello di service instanziato alla root
        const agesString = e.url.substr(e.url.indexOf('/eta/')).split('/')[2];
        if (agesString) {
          const agesArray = agesString.split(',').map(x => parseInt(x, 10));
          this.occupancy.ages = agesArray;
          this.occupancy.guests = agesArray.length;
        }
      }
    });
  }

  getAgesArray(): number[] {
    return this.occupancy.ages.filter(x => Number.isInteger(x));
  }

  setNewAges(newAges: number[]): void {
    this.occupancy.ages = [...newAges];
    this.occupancy$.next(this.occupancy);
  }

  getAgesString(): string {
    return this.getAgesArray().join(',');
  }

  getChildAgencies(token: string): Promise<IHolidayQuotationAgency[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);

    const url = `${environment.otoApi2UrlDynamic}agency/children`;
    const key = url + JSON.stringify(token);
    if (!this.httpCache.get(key)) {
      this.httpCache.set(key, this._http.get<any>(url, { headers }).pipe(shareReplay({bufferSize: 1, refCount: true})));
    }

    return this.httpCache.get(key).pipe(
      map(response => response.result)
    ).toPromise();
  }

}
