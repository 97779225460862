import {Pipe, PipeTransform} from '@angular/core';
import {ICruiseSolution} from '../../booking-flow/cruise/cruise-search-page/interfaces/icruise-solution';
import {ICruiseSpecialStamp} from '../interfaces/icruise-special-stamp';

@Pipe({
  name: 'cruiseSpecialsStamps'
})
export class CruiseSpecialsStampsPipe implements PipeTransform {

  transform(value: ICruiseSolution[], cruiseSpecialsStamps: ICruiseSpecialStamp[], wtg: boolean = false): ICruiseSpecialStamp[] {
    const specialsWithStamp: ICruiseSpecialStamp[] = [];
    for (const solution of value) {
      if (solution.specials && solution.specials.length > 0) {
        for (const special of solution.specials) {
          const specialStamp = cruiseSpecialsStamps.find((e) => e?.id === special?.id);
          const isAlreadyIntoSpecialsWithStamps = !!specialsWithStamp.find((e) => e?.id === specialStamp?.id);
          if (!isAlreadyIntoSpecialsWithStamps) {
            specialsWithStamp.push(specialStamp);
          }
        }
      }
    }
    return specialsWithStamp.filter(x => x.wtg === wtg);
  }

}
