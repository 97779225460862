export const noFlight = [1, 4, 19, 21, 25, 35];
export const withFlight = [2, 11, 16];
export const withHarbours = [6];
export const withBus = [4];
export const busHolidayTypeList = [4, 35, 30, 26, 25];
export const tourHolidayTypeList = [11, 16, 21, 24, 25, 26, 28, 30, 32, 33, 35];
export const ALL_HOLIDAY_TYPES = [1, 2, 3, 4, 11, 16, 21, 24, 25, 26, 28, 30, 32, 33, 35];

export default {
  noFlight,
  withFlight,
  withHarbours,
  withBus,
  busHolidayTypeList,
  tourHolidayTypeList
};
  