import {Pipe, PipeTransform} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {RemoteMappingItem} from '../interfaces/remote-mapping-item';
import {MappingService} from '../services/mapping.service';

type mappingType = 'destination' | 'itinerary' | 'parentDestination' | 'basis' | 'airportId' | 'airportCode';

@Pipe({
  name: 'getDataFromMapping'
})
export class GetDataFromMappingPipe implements PipeTransform {

  private mappingType = {
    destination: 'getDestinationByID',
    itinerary: 'getItineraryById',
    parentDestination: 'getDestinationByID',
    basis: 'getBasisByID',
    top: 'getTourOperatorByID',
    airportId: 'getAirportByID',
    airportCode: 'getAirportByCode'
  };

  constructor(private _mappingService: MappingService) { }

  transform(value: number | number[], type: mappingType): Observable<any> {
    if (Array.isArray(value)) {
      const subs: Observable<RemoteMappingItem>[] = [];
      value.forEach((item) => {
        subs.push(this._mappingService[this.mappingType[type]](item)!);
      });

      return value ? forkJoin(subs).pipe(map((response) => response)) : of([{ Name: null, Id: null } as RemoteMappingItem]);
    } else {
      return value ? this._mappingService[this.mappingType[type]](value) : of({Name: null, Id: null});
    }
  }

}
