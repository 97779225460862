import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TemplatesService} from '../../services/templates.service';
import {CompilerService} from '../../services/compiler.service';
import {MailerService} from '../../services/mailer.service';
import {TemplateTypes} from '../../models/template-types.enum';
import {catchError, finalize, map, mergeMap, shareReplay} from 'rxjs/operators';
import {forkJoin, Observable, of, Subscription, throwError} from 'rxjs';
import {ProjectService} from '../../services/project.service';
import {LoadingItem} from '../../interfaces/loading-item';
import {ModalGenericInfoComponent} from '../modal-generic-info/modal-generic-info.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'app-info-request',
  templateUrl: './info-request.component.html',
  styleUrls: ['./info-request.component.scss']
})
export class InfoRequestComponent implements OnInit, OnDestroy {

  @Input() packetInfo: string;
  @Input() packetUrl: string;
  profile$: Observable<any>;
  name: string;
  surname: string;
  email: string;
  telephone: number;
  infoSubscription: Subscription;
  passengers = 2;
  ages: number[] = [35, 35];

  message: string;
  messageClass: string;

  openFormInfo = true;
  loadingStatus = false;
  loadingMessage = 'Invio Richiesta in corso...';

  constructor(private _modalService: NgbModal,
              private _templatesService: TemplatesService,
              private _compilerService: CompilerService,
              private _mailerService: MailerService,
              private _loadingService: LoadingService,
              private _projectService: ProjectService) {
  }

  ngOnInit(): void {
    this.profile$ = this._projectService.getProfile();
  }

  setAgesArray($event): void {
    while ($event > this.ages.length) {
      this.ages.push(35);
    }
    while ($event < this.ages.length) {
      this.ages.pop();
    }
  }

  trackByAges(index: number): number {
    return index;
  }

  openPrivacy($event: any): void {
    $event?.preventDefault();
    const loadingPrivacy: LoadingItem = {id: 'privacy', message: 'Cariamento informativa privacy...'};
    this._loadingService.addLoad(loadingPrivacy);
    forkJoin([
      this._templatesService.getTemplate(TemplateTypes.GEO_PRIVACY_CONTACTS),
      this.profile$
    ]).pipe(
      map(e => this._compilerService.compile(e[0], e[1])),
      finalize(() => this._loadingService.removeLoad(loadingPrivacy)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    ).subscribe(p => {
      const modalRef = this._modalService.open(ModalGenericInfoComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg'
      });
      modalRef.componentInstance.title = 'Informativa privacy richiedi informazioni';
      modalRef.componentInstance.body = p;
    });
  }

  submitRequest(): void {
    this.loadingStatus = true;
    this.infoSubscription = this._templatesService.getTemplate(TemplateTypes.INFO).pipe(
      map(e => this._compilerService.compile(e, {
        packetInfo: this.packetInfo,
        packetUrl: this.packetUrl,
        paxString: this.ages.join(', '),
        name: this.name,
        surname: this.surname,
        email: this.email,
        telephone: this.telephone
      })),
      mergeMap(e => forkJoin([
        of(e),
        this.profile$
      ])),
      mergeMap(e => this._mailerService.sendEmail({
        from: this.email,
        to: e[1].email_1,
        body: e[0],
        title: `Richiesta Informazioni per ${this.packetInfo}`
      }))
    ).subscribe(e => {
      this.loadingStatus = false;
      if (e) {
        this.message = 'Richiesta inviata con successo!';
        this.messageClass = 'alert-success';
        this.resetForm();
      } else {
        this.message = 'Si e\' verificato un\'errore';
        this.messageClass = 'alert-danger';
      }
    });
  }

  resetForm(): void {
    this.name = undefined;
    this.surname = undefined;
    this.telephone = undefined;
    this.email = undefined;
  }

  ngOnDestroy(): void {
    this.infoSubscription?.unsubscribe();
  }

}
