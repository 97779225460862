import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IEnvironment } from '../interfaces/ienvironment';
import { PostMessageService } from './post-message.service';
import { WidgetService } from './widget.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  private readonly otoApikey$: Observable<string> = of('db7828797eca9b2d01a7b56f1d0c900468b0ca34');
  private readonly networkApikey$: Observable<string> = of('eba9b9ee-e63b-4ae8-a3b2-26315e8908eb');
  private readonly environment$: Observable<any>;
  private readonly allowedNetworks$: Observable<{ id: number, name: string }[]> = of([
    {id: 16, name: 'Welcome Travel Group'},
    {id: 46, name: 'GEO'}
  ]);

  constructor(private _widgetService: WidgetService,
              private _postMessageService: PostMessageService) {
    this.environment$ = of(environment);
  }

  getAllowedNetworks(): Observable<{ id: number, name: string }[]> {
    return this.allowedNetworks$;
  }

  getOtoApikey(): Observable<string> {
    return this.otoApikey$;
  }

  getNetworkApikey(): Observable<string> {
    return this.networkApikey$;
  }

  getCurrentWebsite(): Observable<string> {
    const testingwebsite = localStorage.getItem('oto-fe-testingwebsite') ?? 'www.maracaiboviaggi.it' ;
    return this._widgetService.isWidget().pipe(
      mergeMap(e => e ? this._postMessageService.getWebsite() : of(window.location.hostname)),
      map(e => (e === 'localhost' || e === 'core2.offertetouroperator.com' || e === 'website.offertetouroperator.com') ? testingwebsite : e), // per i test in locale o in ambiente di test remoto
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getEnvironment(): Observable<IEnvironment> {
    return this.environment$;
  }
}
