import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import { IRemoteAgencyNetwork } from '../interfaces/iremote-agency-network';
import { IRemoteAgencyNetworkProfile } from '../interfaces/iremote-agency-network-profile';
import { IRemoteProfile } from '../interfaces/iremote-profile';
import { Extras } from '../models/extras.enum';
import { Networks } from '../models/networks.enum';
import { OnlyNetProjectTypes } from '../models/only-net-project-types.enum';
import { ProjectTypes } from '../models/project-types.enum';
import { Sources } from '../models/sources.enum';
import { environment } from './../../../../environments/environment.prod';
import { EnvironmentService } from './environment.service';
import { PostMessageService } from './post-message.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private _http: HttpClient,
              private _postMessageService: PostMessageService,
              private _environmentService: EnvironmentService) {
  }

  httpCache: Map<string, Observable<any>> = new Map<string, Observable<any>>();

  formatProfile(profile: IRemoteProfile): IRemoteProfile {
    if (!profile.logo.startsWith('http')) {
      profile.logo = `https://cdn.otosrl.com/images/clienti/${profile.logo}`;
    }
    return profile;
  }

  formatWebsiteForProject(website: string): string {
    let finalUrl = website.trim();
    if (finalUrl) {
      if (finalUrl.startsWith('//')) {
        finalUrl = `https:${finalUrl}`;
      } else if (!finalUrl.startsWith('http')) {
        finalUrl = `https://${finalUrl}`;
      }
      return new URL(finalUrl).hostname;
    }
    return undefined;
  }

  getProject(): Observable<any> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getProfileFromApi().pipe(
        map(x => this.formatWebsiteForProject(x.sito))) : this._environmentService.getCurrentWebsite()
      ),
      mergeMap(e => forkJoin([
        this._environmentService.getEnvironment(),
        this._environmentService.getOtoApikey(),
        of(e)
      ]).pipe(
        mergeMap(x => {
          const url = `${x[0].otoApiUrl}/index.php/admin/${x[1]}/get/clients/project/${x[2]}`;
          if (!this.httpCache.get(url)) {
            this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
          }
          return this.httpCache.get(url);
        }),
        map(x => x?.result),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
      ))
    );
  }

  getProjectType(): Observable<ProjectTypes> {
    return this.getProject().pipe(
      map(e => e?.project?.type || ProjectTypes.GEO_BASIC), // FIXME: controllare logica quando avremo piu' network
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  hasOnlyNetQuotes(): Observable<boolean> {
    return forkJoin([
      this._postMessageService.isB2B(),
      this.getProjectType(),
      this.getAgencyNetwork()
    ]).pipe(
      map(e => {
        const [isB2B, projectType, agencyNetwork] = e;
        if (isB2B) {
          return true;
        }
        if (parseInt(agencyNetwork?.id_network, 10) === Networks.GEO) {
          return Object.values(OnlyNetProjectTypes).includes(projectType);
        }
        return false;
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getProfileFromProject(): Observable<IRemoteProfile> {
    return this.getProject().pipe(
      map(e => this.formatProfile(e.profile)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getProfileFromApi(): Observable<IRemoteProfile> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this.getAgencyApikey()
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].otoApiUrl}/index.php/configuration/${e[1]}/get/profile`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => this.formatProfile(e.profile)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getMasterProfileFromApi(): Observable<IRemoteProfile> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._environmentService.getNetworkApikey()
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].otoApiUrl}/index.php/configuration/${e[1]}/get/profile`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => this.formatProfile(e.profile)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getProfile(): Observable<IRemoteProfile> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getProfileFromApi() : this.getProfileFromProject()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getMasterProfile(): Observable<IRemoteProfile> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getMasterProfileFromApi() : this.getProfileFromProject()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getServiceKey(): Observable<string> {
    return this.getAgencyNetworkProfile().pipe(
      map(e => e ? e.service_id ? e.service_id : e.ref_id ? e.ref_id : e.external_id : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getAgencyNetworkFromProject(): Observable<IRemoteAgencyNetwork> {
    return this.getProject().pipe(
      map(e => e.networks ? e.networks[0] : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getAgencyNetworkFromApi(): Observable<IRemoteAgencyNetwork> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this.getAgencyApikey()
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].otoApiUrl}/index.php/configuration/${e[1]}/get/networks`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e.networks ? e.networks[0] : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAgencyNetwork(): Observable<IRemoteAgencyNetwork> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getAgencyNetworkFromApi() : this.getAgencyNetworkFromProject()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getWebsiteConfig(): Observable<any[]> {
    return this.getProject().pipe(
      map(e => e.website ? e.website : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getAgencyNetworkProfileFromApi(): Observable<IRemoteAgencyNetworkProfile> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this.getAgencyApikey(),
      this.getAgencyNetwork(),
    ]).pipe(
      mergeMap(e => {
        const [env, apikey, agencyNetwork] = e;
        const url = `${env.otoApiUrl}/index.php/configuration/${apikey}/get/affiliateProfile/id/${agencyNetwork.id_network}/${agencyNetwork.id_network_agency}`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e.affiliateProfile),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getAgencyNetworkProfileFromProject(): Observable<IRemoteAgencyNetworkProfile> {
    return forkJoin([
      this.getProject(),
      this.getAgencyNetwork(),
    ]).pipe(
      map(e => {
        const [project, agencyNetwork] = e;
        if (agencyNetwork) {
          return project.networkProfiles ? project.networkProfiles.find(x => agencyNetwork.id_network === x.id_network) : undefined;
        }
        return undefined;
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAgencyNetworkProfile(): Observable<IRemoteAgencyNetworkProfile> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getAgencyNetworkProfileFromApi() : this.getAgencyNetworkProfileFromProject()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getExtras(): Observable<Extras[]> {
    return this.getAgencyNetworkProfile().pipe(
      map(e => {
        if (e) {
          return e.custom_fields ? e.custom_fields.split(',').map(x => {
            for (const [key, value] of Object.entries(Extras)) {
              if (value === x) {
                return Extras[key];
              }
            }
          }) : [];
        }
        return [];
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  hasNewsletter(): Observable<boolean> {
    return this.getExtras().pipe(
      map(e => {
        let hasNewsletter = false;
        e.forEach(x => {
          if (x === Extras.GEO_NEWSLETTER) {
            hasNewsletter = true;
          }
        });
        return hasNewsletter;
      })
    );
  }

  hasWelcomeToItalyTabs(): Observable<boolean> {
    return this.getAgencyNetwork().pipe(
      map(agencyNetworkObj =>
        (Number(agencyNetworkObj.id_network) === Networks.WTG || Number(agencyNetworkObj.id_network) === Networks.GEO)
      )
    );
  }

  hasMerlinxTabs(): Observable<boolean> {
    return this.getAgencyNetwork().pipe(
      mergeMap(e => {
        switch (parseInt(e.id_network, 10)) {
          case Networks.WTG:
            return this.getAgencyNetworkProfile().pipe(
              map(x => x.custom_fields?.toLowerCase().includes('thirdpart'))
            );
          case Networks.GEO:
            return this.getExternalCode(Sources.MerlinxWTG).pipe(
              map(x => !!x)
            );
          default:
            return of(false);
        }
      })
    );
  }

  getMerlinxCode(): Observable<string> {
    return this.getAgencyNetwork().pipe(
      mergeMap(e => {
        switch (parseInt(e.id_network, 10)) {
          case Networks.WTG:
            return this.getAgencyNetworkProfile().pipe(
              map(x => x.external_id)
            );
          case Networks.GEO:
            return this.getExternalCode(Sources.MerlinxWTG);
          default:
            return of(undefined);
        }
      })
    );
  }

  getExternalCode(source: string | number): Observable<string> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const [env, apikey] = e;
        const url = `${env.otoApiUrl}/index.php/customer/getcode/${apikey}/source/${source}`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e?.Result || undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getAgencyApikeyFromProject(): Observable<string> {
    return this.getProject().pipe(
      map(e => e.widgets.filter(x => x.id_widget === '-1' || x.id_widget === '66').map(x => x.api_key)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAgencyApikey(): Observable<string> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(x => x ? this._postMessageService.getApi() : this.getAgencyApikeyFromProject()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getPaymentApikey(): Observable<string> {
    return this.getAgencyApikey();
  }

  getQuotationApikey(): Observable<string> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getAgencyNetwork()
        .pipe(
          map(x => x?.id_network ? parseInt(x.id_network, 10) === Networks.GEO : undefined),
          mergeMap(x => x ? this._environmentService.getNetworkApikey() : this.getAgencyApikey())
        ) : this.getAgencyApikey()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getLoggedUser(token: string): Promise<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    const url = `${environment.otoApi2UrlDynamic}users`;
    const key = url + JSON.stringify(token);

    if (!this.httpCache.get(key)) {
      this.httpCache.set(key, this._http.get<any>(url, { headers: httpHeaders }).pipe(shareReplay({bufferSize: 1, refCount: true})));
    }

    return this.httpCache.get(key).pipe(
      map(resp => resp.result)
    ).toPromise();
  }

}
