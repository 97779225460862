import {Injectable} from '@angular/core';
import {EnvironmentService} from './environment.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {ICruiseHarbourImage} from '../interfaces/icruise-harbour-image';

@Injectable({
  providedIn: 'root'
})
export class CruiseHarbourImagesService {

  constructor(private _environmentService: EnvironmentService,
              private _http: HttpClient) {
  }

  httpCache = new Map<string, Observable<any>>();

  getHarboutImageByCode(code: string): Observable<string> {
    return this.getFormattedHarbourImages().pipe(
      map(e => {
        const image = e.find(x => x.code === code);
        if (!image) {
          return 'https://cdn.otosrl.com/images/cruises/oto_official/porti/placeholder.jpg';
        }
        return image.url;
      }),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  formatHarbourImage(image: string): ICruiseHarbourImage {
    const [name, code] = image.replace(/\.(jpg|png)/ig, '').split('_');
    return {
      code,
      url: `https://cdn.otosrl.com/images/cruises/oto_official/porti/${image}`
    };
  }

  getFormattedHarbourImages(): Observable<ICruiseHarbourImage[]> {
    return this.getHarbourImages().pipe(
      map(e => e.map(x => this.formatHarbourImage(x))),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  getHarbourImages(): Observable<any> {
    const url = 'https://www.offertetouroperator.com/images/cruises/get_cruise_pictures.php?company=oto_official&name=porti';
    if (!this.httpCache.get(url)) {
      this.httpCache.set(url, this._http.get<any>(url).pipe(
        map(e => e.images),
        shareReplay({bufferSize: 1, refCount: true})
      ));
    }
    return this.httpCache.get(url);
  }
}
