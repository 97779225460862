import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  activeModal$: BehaviorSubject<any>;
  activeModal;

  constructor() { 
    this.activeModal$ = new BehaviorSubject<any>(this.activeModal);
  }

  openModal(type, dataSource) {
    this.activeModal = {
      visible: true,
      type: type,
      dataSource: dataSource
    }
    this.activeModal$.next(this.activeModal);
  }

  closeActiveModal() {
    this.activeModal.visible = false;
    this.activeModal$.next(this.activeModal);
  }
}
