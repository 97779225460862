export const environment = {
  production: true,
  cdnUrl: 'https://cdn.otosrl.com',
  otoApiUrl: 'https://api.offertetouroperator.com',
  searchUrl: 'https://api.offertetouroperator.com',
  otoApi2Url: 'https://api2.offertetouroperator.com',
  otoApi2UrlDynamic: 'https://api2.offertetouroperator.com/oto-api/',
  quotation: 'https://api2.offertetouroperator.com/oto-api/quotation',
  pageBuilder: 'https://api2.offertetouroperator.com/oto-api/generic/',
  site: 'https://api2.offertetouroperator.com/oto-api/generic/nauth/',
  wtiSearchEngineB2b: 'https://b2b.welcometoitaly.com',
  wtiSearchEngineB2bDev: 'https://beta-welcometoitaly.openvoucher.com:8443',
  wtiSearchEngineB2c: 'https://www.welcometoitaly.com',
  wtiSearchEngineB2cDev: 'https://preprod.welcometoitaly.com:8443',
  wtiHubcoreApiProxy: 'https://core2.offertetouroperator.com/~wtg-hubcore-proxy/dist',
  wtiHubcoreApiProxyDev: 'https://core2.offertetouroperator.com/~wtg-hubcore-proxy/dev/development'
};
