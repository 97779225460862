import { Injectable, OnDestroy } from '@angular/core';
import { ProjectService } from '../../shared/services/project.service';
import { PageType } from './../enums/content.enum';
import { Subscription, Observable, BehaviorSubject, throwError, Subject, of } from 'rxjs';
import { catchError, map, takeUntil, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CmsService implements OnDestroy {
  private projectSub: Subscription;

  private apiKey;
  private siteApi = environment.site;
  private api;

  private unsubscribe$ = new Subject;

  public currentPage$: BehaviorSubject<any>;
  public siteMap$: BehaviorSubject<any>;
  public siteMap = null;
  public hasCmsEnabled$: BehaviorSubject<boolean>;
  

  constructor(
    private _projectService: ProjectService,
    private _http: HttpClient
  ) { 
    this.siteMap$ = new BehaviorSubject<any>(this.siteMap);
    this.currentPage$ = new BehaviorSubject<any>(null);
    this.hasCmsEnabled$ = new BehaviorSubject<boolean>(false);
  }

  ngOnDestroy(): void {
    this.projectSub.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public initialise(): void {
    console.log("=== INITIALISE CMS ===");

    this.projectSub = this._projectService.getAgencyApikey().pipe(
      takeUntil(this.unsubscribe$),
      catchError(err => throwError(err))
    ).subscribe(x => {
      this.apiKey = x;
      this.api = `${this.siteApi}${this.apiKey}`;
      this.getHomePage().pipe(takeUntil(this.unsubscribe$)).subscribe(home => {
        if (!home) {
          console.log("CMS NOT ENABLED");
          this.hasCmsEnabled$.next(false);
        } else {
          console.log(home);
          this.hasCmsEnabled$.next(true);
        }
      });
      this.getSitemap().pipe(takeUntil(this.unsubscribe$)).subscribe(siteMap => {
        if (!siteMap) {
          console.log("SITEMAP NOT AVAILABLE");
        } else {
          this.siteMap = siteMap;
          this.siteMap$.next(siteMap);
        }
      });

    });
  }

  public getPages(): any {
    if (this.siteMap) {
      return this.siteMap;
    } else {
      this.getSitemap().pipe(takeUntil(this.unsubscribe$)).subscribe(siteMap => {
        if (!siteMap) {
          console.log("SITEMAP NOT AVAILABLE");
          return null;
        } else {
          this.siteMap = siteMap;
          this.siteMap$.next(siteMap);
          return this.siteMap;
        }
      });
    }
   
  }

  public getHomePage(): Observable<any> {
    const siteSlim = `${this.api}/homePageSlim`;
    return this._http.get(siteSlim).pipe(
      map((site: any)=> {
        if (site.error) {
          return null;
        }
        const home = site.result.pages.find(x => x.hierarchyLevel == 0);
        this.currentPage$.next(home);
        return home;
      })
    );
  }

  public getNews(): Observable<any> {
    const pagesApi = `${this.api}/site/page?typeId=${PageType.News}`;
    return this._http.get(pagesApi).pipe(
      map((news: any)=> {
        if (news.error) {
          return null;
        }
        news.result.sort((a, b) => {
          return (a.progressive < b.progressive) ? -1 : (a.progressive > b.progressive) ? 1 : 0;
        });
        return news.result;
      })
    );
  }

  public getPageById(id): Observable<any> {
    const pageApi = `${this.api}/pageSlim?pageId=${id}`;
    return this._http.get(pageApi).pipe(
      map((page: any)=> {
        if (page.error) {
          return null;
        }
        return page.result;
      })
    );
  }

  public getSitemap(): Observable<any> {
    if (this.siteMap) {
      return of(this.siteMap);
    } else {
      const sitemapApi = `${this.api}/siteMapInformation`;
      return this._http.get(sitemapApi).pipe(
        map((sitemap: any)=> {
          if (sitemap.error) {
            return null;
          }
          return sitemap.result;
        })
      );
    }
  }

  //#region Helpers

  public stripHtml(content) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = content;
    return tmp.textContent || tmp.innerText || "";
  }

  //#endregion end of Helpers



}
