import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import { IEmailParams } from '../interfaces/iemail-params';
import { EnvironmentService } from './environment.service';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root'
})
export class MailerService {

  constructor(private _environmentService: EnvironmentService,
              private _projectService: ProjectService,
              private _http: HttpClient) {
  }

  sendEmail(params: IEmailParams): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
       /* if (!e[0].production) {
          params.to = 'it@offertetouroperator.com';
        }*/
        // params.to = 'it@offertetouroperator.com';

        const url = `${e[0].otoApiUrl}/index.php/sendemail/${e[1]}`;
        // const url = `http://localhost:9003/index.php/sendemail/${e[1]}`;
        return this._http.post<any>(url, params).pipe(shareReplay({bufferSize: 1, refCount: true}));
      }),
      map(e => !e.error),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }
}
