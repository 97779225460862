import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent implements OnInit {

  @Input() elements: string[];
  @Input() selectedElement: string;

  config = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    }
  };
  configThumbs = {
    breakpoints: {
      320: {
        slidesPerView: 2
      },
      480: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 5
      },
      1200: {
        slidesPerView: 7
      },
      1600: {
        slidesPerView: 10
      }
    },
    centeredSlides: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  };
  index;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.index = this.elements.indexOf(this.selectedElement) || 0;
  }

}
