import {Component, OnInit} from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {Observable} from 'rxjs';
import {IRemoteProfile} from '../../interfaces/iremote-profile';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  profile$: Observable<IRemoteProfile>;
  constructor(private _projectService: ProjectService) { }

  ngOnInit(): void {
    this.profile$ = this._projectService.getProfile();
  }

}
