<div class="modal-body modal-custom__body">
  <button type="button" class="close modal-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="icon-close"></i>
  </button>
  <div class="detail-carousel">
    <swiper [config]="config" [(index)]="index" class="c-detail-slider">
      <div class="c-detail-slider-item" *ngFor="let image of elements">
        <div class="c-detail-slider-item__bg c-detail-slider-item__overlay"
             [ngStyle]="{'background-image': 'url(' + (image | cdnify) + ')'}">
        </div>
      </div>
    </swiper>
  </div>
  <div class="detail-carousel-thumbnails">
    <swiper [config]="configThumbs" [(index)]="index" class="c-detail-slider-thumbs">
      <div class="c-detail-slider-thumbs__img" *ngFor="let image of elements">
        <img [src]="(image | cdnify)" alt="" (click)="image">
      </div>
    </swiper>
  </div>
</div>
