<div class="date-calendar">
  <nav aria-label="Page navigation">
    <ul class="pagination date-calendar_bar">
      <li class="page-item date-calendar_bar-item" [ngClass]="{'disabled': currentPage<2}">
        <a class="page-link date-calendar_bar-link" aria-label="Previous" (click)="goToPreviousPage()">
          <span aria-hidden="true"><i class="icon-chevron-left"></i></span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li class="page-item date-calendar_bar-item" [ngClass]="{'active': date.active, 'disabled': date.disabled}" *ngFor="let date of calendar | slice: (currentPage-1) * pageSize : currentPage * pageSize">
        <a class="page-link date-calendar_bar-link" (click)="searchDate(date)">
          <span class="date-calendar_bar-link-day">{{date.dateString | dayjs:'dddd'}}</span>
          <span class="date-calendar_bar-link-date">
              <span class="date-calendar_bar-link-date-number">{{date.dateString | dayjs:'DD'}}</span>
              <span class="date-calendar_bar-link-date-month">
                <span>{{date.dateString | dayjs:'MMM'}}</span>
                <span>{{date.dateString | dayjs:'YYYY'}}</span>
              </span>
            </span>
        </a>
        <a class="page-link date-calendar_bar-link" (click)="searchDate(date)" *ngIf="false">
          <span class="date-calendar_bar-link-date">
            <span>{{date.dateString | dayjs:'MMMM'}}</span>
          </span>
          <span class="date-calendar_bar-link-day">{{date.dateString | dayjs:'YYYY'}}</span>
        </a>
      </li>
      <li class="page-item date-calendar_bar-item" [ngClass]="{'disabled': currentPage>=totalPages}">
        <a class="page-link date-calendar_bar-link" aria-label="Next" (click)="goToNextPage()">
          <span aria-hidden="true"><i class="icon-chevron-right"></i></span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
