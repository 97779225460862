import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import sanitizeForUrl from '../../../utils/sanitizeForUrl';
import { HolidayCard } from '../../booking-flow/holiday/holiday-search-page/interfaces/holiday-card';

@Pipe({
  name: 'holidayDetailUrl'
})
export class HolidayDetailUrlPipe implements PipeTransform {

  transform(holiday: HolidayCard): string {
    if (holiday && holiday.bestSolution) {
      const dateFrom = dayjs(holiday.bestSolution.dateFrom).format('DD-MM-YYYY');
      const dateTo = dayjs(holiday.bestSolution.dateTo).format('DD-MM-YYYY');
      const packetId = holiday.isLive ? holiday.bestSolution.source : holiday.bestSolution.id;
      const productId = holiday.tour ? holiday.tour.id : holiday.accomodation.id;
      const topId = holiday.tourOperator;
      return `/vacanze/dettaglio/${productId}/${topId}/${packetId}/${holiday.pluginSource}/struttura/${sanitizeForUrl(holiday.title)}/dal/${dateFrom}/al/${dateTo}`;
    }
    return null;
  }

}
