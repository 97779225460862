import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {forkJoin, Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import { PostMessageService } from '../services/post-message.service';
import {ProjectService} from '../services/project.service';

@Injectable({
  providedIn: 'root'
})
export class MerlinxGuard implements CanActivate {

  constructor(private _projectService: ProjectService, private _postMessageService: PostMessageService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return forkJoin([
      this._projectService.hasMerlinxTabs(),
      this._postMessageService.getMerlinxToken()
    ]).pipe(
      map(resolved => !!resolved[1] || resolved[0])
    )
  }

}
