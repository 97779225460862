import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgPipesModule } from 'ngx-pipes';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { DynamicNavigationDirective } from '../../directives/dynamic-navigation.directive';
import { BusStopsModalComponent } from '../booking-flow/bus/bus-search-page/components/bus-stops-modal/bus-stops-modal.component';
import { HolidayOrderPipe } from '../booking-flow/holiday/holiday-search-page/pipes/holiday-order.pipe';
import { OrderByBrandAndPricePipe } from '../booking-flow/holiday/holiday-search-page/pipes/order-by-brand-and-price.pipe';
import { OrderByCustomPipe } from '../booking-flow/holiday/holiday-search-page/pipes/order-by-custom.pipe';
import { HolidaySearchCalendarComponent } from '../booking-flow/holiday/holiday-search-page/components/holiday-search-calendar/holiday-search-calendar.component';
import { BookingGuestsInfoComponent } from './components/booking-guests-info/booking-guests-info.component';
import { BookingPassengersComponent } from './components/booking-passengers/booking-passengers.component';
import { BookingStepsComponent } from './components/booking-steps/booking-steps.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ElementLoaderComponent } from './components/element-loader/element-loader.component';
import { GalleryModalComponent } from './components/gallery-modal/gallery-modal.component';
import { InfoRequestComponent } from './components/info-request/info-request.component';
import { ModalGenericInfoComponent } from './components/modal-generic-info/modal-generic-info.component';
import { UiModalComponent } from './components/ui-modal/ui-modal.component';
import { GuestBirthdateValidatorDirective } from './directives/guest-birthdate-validator.directive';
import { ExperienceDetailComponent } from './modals/experience-detail/experience-detail.component';
import { ImageGalleryComponent } from './modals/image-gallery/image-gallery.component';
import { RoomDetailComponent } from './modals/room-detail/room-detail.component';
import { StructureDetailComponent } from './modals/structure-detail/structure-detail.component';
import { AdaptCruiseDatePipe } from './pipes/adapt-cruise-date.pipe';
import { AptDirectionPipe } from './pipes/apt-direction.pipe';
import { CdnifyPipe } from './pipes/cdnify.pipe';
import { ChunkPipe } from './pipes/chunk.pipe';
import { CruiseDetailUrlPipe } from './pipes/cruise-detail-url.pipe';
import { CruiseSpecialsStampsPipe } from './pipes/cruise-specials-stamps.pipe';
import { DateDifferencePipe } from './pipes/date-difference.pipe';
import { DayjsPipe } from './pipes/dayjs.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FormatPrefetchPipe } from './pipes/format-prefetch.pipe';
import { GetDataFromMappingPipe } from './pipes/get-data-from-mapping.pipe';
import { HolidayDetailUrlPipe } from './pipes/holiday-detail-url.pipe';
import { ImageByCodePipe } from './pipes/image-by-code.pipe';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { NgbDatePipe } from './pipes/ngb-date.pipe';
import { OperativeDirectionPipe } from './pipes/operative-direction.pipe';
import { TrustHtmlPipe } from './pipes/trust-html.pipe';
import { PacketDetailComponent } from './modals/packet-detail/packet-detail.component';
import { NetQuotePipe } from './pipes/net-quote.pipe';

registerLocaleData(localeIt);

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  centeredSlides: true
};

const components = [
  BreadcrumbComponent,
  InfoRequestComponent,
  ElementLoaderComponent,
  BookingStepsComponent,
  BookingPassengersComponent,
  BookingGuestsInfoComponent,
  ModalGenericInfoComponent,
  GalleryModalComponent,
  UiModalComponent,
  ExperienceDetailComponent,
  RoomDetailComponent,
  ImageGalleryComponent,
  HolidaySearchCalendarComponent,
  BusStopsModalComponent
];

const pipes = [
  AdaptCruiseDatePipe,
  CdnifyPipe,
  ChunkPipe,
  CruiseDetailUrlPipe,
  DateDifferencePipe,
  DayjsPipe,
  FormatPrefetchPipe,
  FilterPipe,
  HolidayDetailUrlPipe,
  LimitToPipe,
  NgbDatePipe,
  TrustHtmlPipe,
  AptDirectionPipe,
  OperativeDirectionPipe,
  GetDataFromMappingPipe,
  ImageByCodePipe,
  CruiseSpecialsStampsPipe,
  OrderByBrandAndPricePipe,
  HolidayOrderPipe,
  OrderByCustomPipe,
  NetQuotePipe
];

const directives = [
  GuestBirthdateValidatorDirective
];

const modules = [
  CommonModule,
  HttpClientModule,
  FormsModule,
  NgbModule,
  SwiperModule,
  NgPipesModule,
  NgxSliderModule,
  NgSelectModule,
  GoogleMapsModule,
  YouTubePlayerModule,
  NgxImageZoomModule
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes,
    StructureDetailComponent,
    DynamicNavigationDirective,
    PacketDetailComponent
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components,
    ...directives,
    ...pipes,
    DatePipe,
    CurrencyPipe,
    TitleCasePipe,
    DynamicNavigationDirective
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'it'},
    {provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG},
    DatePipe,
    TitleCasePipe,
    CurrencyPipe,
    OrderByBrandAndPricePipe
  ],
  schemas: []
})
export class SharedModule {
}
