export enum CtaType {
  DETAIL = 1,
  MULTIPRODUCT = 2,
  NEWS = 4,
  EXTERNAL_LINK = 3,
  BLOG_POST = 998
}

export enum ContentType {
  NEWS = 2
}

export enum PageType {
  Homepage = 1,
  News = 2,
  Multiproduct = 3
}

export enum ProductType {
  Holiday = 1,
  Cruise = 2,
  Tour = 3,
  Experience = 4
}